import React from 'react';
import { Selected } from "./styled";
import PropTypes from 'prop-types';

function Select({ options, onSelectChange, id, name, value }) {

  return (
    <Selected>
      <div className='area-select'>
        <select
          name={name}
          id={id}
          onChange={onSelectChange}
          value={value}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </Selected>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Select;
