import React from "react";
import Select from "./Select";
// import SelectCityNte from "./optionCityNte";
import { OptionsNte } from "./styled";
import PropTypes from 'prop-types';


function SelectNte({ value, onChange }) {

    // const [selecNtetOptions, setSelecNtetOptions] = useState('');

    // const handleNteOptionSelect = (event) => {
    //     setSelecNtetOptions(event.target.value)
    // }

    const options = [
        { value: '', label: 'Selecione uma opção' },
        { value: 'NTE 01 - Irecê', label: 'NTE 01 - Irecê' },
        { value: 'NTE 02 - Bom Jesus da Lapa', label: 'NTE 02 - Bom Jesus da Lapa' },
        { value: 'NTE 03 - Seabra', label: 'NTE 03 - Seabra' },
        { value: 'NTE 04 - Serrinha', label: 'NTE 04 - Serrinha' },
        { value: 'NTE 05 - Itabuna', label: 'NTE 05 - Itabuna' },
        { value: 'NTE 06 - Valença', label: 'NTE 06 - Valença' },
        { value: 'NTE 07 - Teixeira de Freitas', label: 'NTE 07 - Teixeira de Freitas' },
        { value: 'NTE 08 - Itapetinga', label: 'NTE 08 - Itapetinga' },
        { value: 'NTE 09 - Amargosa', label: 'NTE 09 - Amargosa' },
        { value: 'NTE 10 - Juazeiro', label: 'NTE 10 - Juazeiro' },
        { value: 'NTE 11 - Barreiras', label: 'NTE 11 - Barreiras' },
        { value: 'NTE 12 - Macaubas', label: 'NTE 12 - Macaubas' },
        { value: 'NTE 13 - Caetité', label: 'NTE 13 - Caetité' },
        { value: 'NTE 14 - Itaberaba', label: 'NTE 14 - Itaberaba' },
        { value: 'NTE 15 - Ipirá', label: 'NTE 15 - Ipirá' },
        { value: 'NTE 16 - Jacobina', label: 'NTE 16 - Jacobina' },
        { value: 'NTE 17 - Ribeira do Pombal', label: 'NTE 17 - Ribeira do Pombal' },
        { value: 'NTE 18 - Alagoinhas', label: 'NTE 18 - Alagoinhas' },
        { value: 'NTE 19 - Feira de Santana', label: 'NTE 19 - Feira de Santana' },
        { value: 'NTE 20 - Vitória da Conquista', label: 'NTE 20 - Vitória da Conquista' },
        { value: 'NTE 21 - Santo Antônio de Jesus', label: 'NTE 21 - Santo Antônio de Jesus' },
        { value: 'NTE 22 - Jequié', label: 'NTE 22 - Jequié' },
        { value: 'NTE 23 - Santa Maria da Vitória', label: 'NTE 23 - Santa Maria da Vitória' },
        { value: 'NTE 24 - Paulo Afonso', label: 'NTE 24 - Paulo Afonso' },
        { value: 'NTE 25 - Senhor do Bonfim', label: 'NTE 25 - Senhor do Bonfim' },
        { value: 'NTE 26 - Salvador', label: 'NTE 26 - Salvador' },
        { value: 'NTE 27 - Eunápolis', label: 'NTE 27 - Eunápolis' },
    ];

    return (
        <OptionsNte>
            <div className="select-nte">
                <label className="acting-nte">Qual a sua NTE?</label>
                <Select options={options} onSelectChange={onChange} value={value} />
            </div>
        </OptionsNte>

    );

};

SelectNte.prototypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectNte;
