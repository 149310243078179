import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
 }
body{
   margin: 1px;
   background: linear-gradient(to right bottom, #f4fcfc, #ecf4fc, #d9e9f2, #ecf4fc, #bcd4e4, #b4dce8, #8dd3e8);
   background-repeat: no-repeat;
   background-size: cover; 
   // background-position: center center;
   height: 100vh;
}

@media (max-width:600px){
   body{
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
   }
}
@media (max-width:375px){
   body{
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      background: #FFFFFF 0% 0% ;
   }
}

`;

export default Global;