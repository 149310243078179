import {
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

import { FormAutentication } from "./components/Form";

const RoutesApp = () => {

  return (
    <Router>
      <Routes>
        <Route exact path="/autentica/:numTelefone" element={<FormAutentication />} />
      </Routes>
    </Router>
  );
};

export default RoutesApp;