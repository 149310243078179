import axios from 'axios';

const url = process.env.REACT_APP_GLPI_URL;

export const api = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  },
})

export const validateEmail = async (user_email) => {
  return await api.post('user/getUserForEmail', { user_email });
}

export const validateLogin = async (user_login) => {
  return await api.post('user/getUserForLogin', { user_login });
}
