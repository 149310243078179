import {useState, useEffect} from "react";
import Global from "./styles/global"
import RoutesApp from "./routes";
import { Loader } from "./components/Loader/loader";

function App() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
        setLoading(false);
    },4000)

}, [])


  return (
    <>
      <Global />
      {
        loading?
        <Loader/>
        :
        <RoutesApp />
      }
      
    </>
  );
}

export default App;