
import React from "react";
import Select from "./Select";
import PropTypes from 'prop-types';
import { OptionCityNte } from "./styled";


function SelectCityNte({ value, onChange }) {

    // const [selecCityNtetOptions, setSelecCityNtetOptions] = useState('');

    // const handleCityNteOptionSelect = (event) => {
    //     setSelecCityNtetOptions(event.target.value)
    // }

    const options = [
        { value: '', label: 'Selecione uma opção' },
        { value: 'Abaíra', label: 'Abaíra' },
        { value: 'Abaré', label: 'Abaré' },
        { value: 'Acajutiba', label: 'Acajutiba' },
        { value: 'Adustina', label: 'Adustina' },
        { value: 'Agua fria', label: 'Agua fria' },
        { value: 'Aiquara', label: 'Aiquara' },
        { value: 'Alagoinhas', label: 'Alagoinhas' },
        { value: 'Alagoinhas > NTE-18 Alagoinhas', label: 'Alagoinhas > NTE-18 Alagoinhas' },
        { value: 'Alcobaça', label: 'Alcobaça' },
        { value: 'Almadina', label: 'Almadina' },
        { value: 'Amargosa', label: 'Amargosa' },
        { value: 'Amargosa > NTE-09 Amargosa', label: 'Amargosa > NTE-09 Amargosa' },
        { value: 'Amélia Rodrigues', label: 'Amélia Rodrigues' },
        { value: 'América Dourada', label: 'América Dourada' },
        { value: 'Anagé', label: 'Anagé' },
        { value: 'Andaraí', label: 'Andaraí' },
        { value: 'Andorinha', label: 'Andorinha' },
        { value: 'Angical', label: 'Angical' },
        { value: 'Anguera', label: 'Anguera' },
        { value: 'Antas', label: 'Antas' },
        { value: 'Antônio Cardoso', label: 'Antônio Cardoso' },
        { value: 'Antônio Gonçalves', label: 'Antônio Gonçalves' },
        { value: 'Aporá', label: 'Aporá' },
        { value: 'Apuarema', label: 'Apuarema' },
        { value: 'Araçás', label: 'Araçás' },
        { value: 'Aracatu', label: 'Aracatu' },
        { value: 'Araci', label: 'Araci' },
        { value: 'Aramari', label: 'Aramari' },
        { value: 'Arataca', label: 'Arataca' },
        { value: 'Aratuípe', label: 'Aratuípe' },
        { value: 'Aurelino Leal', label: 'Aurelino Leal' },
        { value: 'Baianópolis', label: 'Baianópolis' },
        { value: 'Baixa Grande', label: 'Baixa Grande' },
        { value: 'Banzaê', label: 'Banzaê' },
        { value: 'Barra', label: 'Barra' },
        { value: 'Barra da Estiva', label: 'Barra da Estiva' },
        { value: 'Barra do Choça', label: 'Barra do Choça' },
        { value: 'Barra do Mendes', label: 'Barra do Mendes' },
        { value: 'Barra do Rocha', label: 'Barra do Rocha' },
        { value: 'Barreiras', label: 'Barreiras' },
        { value: 'Barreiras > NTE-11 Barreiras', label: 'Barreiras > NTE-11 Barreiras' },
        { value: 'Barro Alto', label: 'Barro Alto' },
        { value: 'Barro Preto', label: 'Barro Preto' },
        { value: 'Barrocas', label: 'Barrocas' },
        { value: 'Belmonte', label: 'Belmonte' },
        { value: 'Belo Campo', label: 'Belo Campo' },
        { value: 'Biritinga', label: 'Biritinga' },
        { value: 'Boa Nova', label: 'Boa Nova' },
        { value: 'Boa Vista do Tupim', label: 'Boa Vista do Tupim' },
        { value: 'Bom Jesus da Lapa', label: 'Bom Jesus da Lapa' },
        { value: 'Bom Jesus da Lapa > NTE-02 Bom Jesus da Lapa', label: 'Bom Jesus da Lapa > NTE-02 Bom Jesus da Lapa' },
        { value: 'Bom Jesus da Serra', label: 'Bom Jesus da Serra' },
        { value: 'Boninal', label: 'Boninal' },
        { value: 'Bonito', label: 'Bonito' },
        { value: 'Boquira', label: 'Boquira' },
        { value: 'Botuporã', label: 'Botuporã' },
        { value: 'Brejões', label: 'Brejões' },
        { value: 'Brejolândia', label: 'Brejolândia' },
        { value: 'Brotas de Macaúbas', label: 'Brotas de Macaúbas' },
        { value: 'Brumado', label: 'Brumado' },
        { value: 'Buerarema', label: 'Buerarema' },
        { value: 'Buritirama', label: 'Buritirama' },
        { value: 'Caatiba', label: 'Caatiba' },
        { value: 'Cabaceiras do Paraguaçu', label: 'Cabaceiras do Paraguaçu' },
        { value: 'Cachoeira', label: 'Cachoeira' },
        { value: 'Caculé', label: 'Caculé' },
        { value: 'Caém', label: 'Caém' },
        { value: 'Caetanos', label: 'Caetanos' },
        { value: 'Caetite', label: 'Caetite' },
        { value: 'Caetite > NTE-13 Caetité', label: 'Caetite > NTE-13 Caetité' },
        { value: 'Cafarnaum', label: 'Cafarnaum' },
        { value: 'Cairu', label: 'Cairu' },
        { value: 'Caldeirão Grande', label: 'Caldeirão Grande' },
        { value: 'Camacan', label: 'Camacan' },
        { value: 'Camaçari', label: 'Camaçari' },
        { value: 'Camamu', label: 'Camamu' },
        { value: 'Campo Alegre de Lourdes', label: 'Campo Alegre de Lourdes' },
        { value: 'Campo Formoso', label: 'Campo Formoso' },
        { value: 'Canápolis', label: 'Canápolis' },
        { value: 'Canarana', label: 'Canarana' },
        { value: 'Canavieiras', label: 'Canavieiras' },
        { value: 'Candeal', label: 'Candeal' },
        { value: 'Candeias', label: 'Candeias' },
        { value: 'Candiba', label: 'Candiba' },
        { value: 'Cândido Sales', label: 'Cândido Sales' },
        { value: 'Cansanção', label: 'Cansanção' },
        { value: 'Canudos', label: 'Canudos' },
        { value: 'Capela do Alto Alegre', label: 'Capela do Alto Alegre' },
        { value: 'Capim Grosso', label: 'Capim Grosso' },
        { value: 'Caraíbas', label: 'Caraíbas' },
        { value: 'Caravelas', label: 'Caravelas' },
        { value: 'Cardeal da Silva', label: 'Cardeal da Silva' },
        { value: 'Carinhanha', label: 'Carinhanha' },
        { value: 'Casa nova', label: 'Casa nova' },
        { value: 'Castro Alves', label: 'Castro Alves' },
        { value: 'Catolândia', label: 'Catolândia' },
        { value: 'Catu', label: 'Catu' },
        { value: 'Caturama', label: 'Caturama' },
        { value: 'Central', label: 'Central' },
        { value: 'Chorrochó', label: 'Chorrochó' },
        { value: 'Cícero Dantas', label: 'Cícero Dantas' },
        { value: 'Cipó', label: 'Cipó' },
        { value: 'Coaraci', label: 'Coaraci' },
        { value: 'Côcos', label: 'Côcos' },
        { value: 'Conceição da Feira', label: 'Conceição da Feira' },
        { value: 'Conceição do Almeida', label: 'Conceição do Almeida' },
        { value: 'Conceição do Coité', label: 'Conceição do Coité' },
        { value: 'Conceição do Jacuípe', label: 'Conceição do Jacuípe' },
        { value: 'Conde', label: 'Conde' },
        { value: 'Condeúba', label: 'Condeúba' },
        { value: 'Contendas do Sincorá', label: 'Contendas do Sincorá' },
        { value: 'Coração de Maria', label: 'Coração de Maria' },
        { value: 'Cordeiros', label: 'Cordeiros' },
        { value: 'Coribe', label: 'Coribe' },
        { value: 'Coronel João Sá', label: 'Coronel João Sá' },
        { value: 'Correntina', label: 'Correntina' },
        { value: 'Cotegipe', label: 'Cotegipe' },
        { value: 'Cravolândia', label: 'Cravolândia' },
        { value: 'Crisópolis', label: 'Crisópolis' },
        { value: 'Cristópolis', label: 'Cristópolis' },
        { value: 'Cruz das Almas', label: 'Cruz das Almas' },
        { value: 'Curaçá', label: 'Curaçá' },
        { value: 'Dário Meira', label: 'Dário Meira' },
        { value: 'Dias Dávila', label: 'Dias Dávila' },
        { value: 'Dom Basílio', label: 'Dom Basílio' },
        { value: 'Dom Macedo Costa', label: 'Dom Macedo Costa' },
        { value: 'Elísio Medrado', label: 'Elísio Medrado' },
        { value: 'Encruzilhada', label: 'Encruzilhada' },
        { value: 'Entre Rios', label: 'Entre Rios' },
        { value: 'Erico Cardoso', label: 'Erico Cardoso' },
        { value: 'Esplanada', label: 'Esplanada' },
        { value: 'Euclides da Cunha', label: 'Euclides da Cunha' },
        { value: 'Eunapolis', label: 'Eunapolis' },
        { value: 'Eunapolis > NTE-27 Eunápolis', label: 'Eunapolis > NTE-27 Eunápolis' },
        { value: 'Fátima', label: 'Fátima' },
        { value: 'Feira da Mata', label: 'Feira da Mata' },
        { value: 'Feira de Santana', label: 'Feira de Santana' },
        { value: 'Feira de Santana > NTE-19 Feira de Santana', label: 'Feira de Santana > NTE-19 Feira de Santana' },
        { value: 'Filadélfia', label: 'Filadélfia' },
        { value: 'Firmino Alves', label: 'Firmino Alves' },
        { value: 'Floresta Azul', label: 'Floresta Azul' },
        { value: 'Formosa do Rio Preto', label: 'Formosa do Rio Preto' },
        { value: 'Gandu', label: 'Gandu' },
        { value: 'Gavião', label: 'Gavião' },
        { value: 'Gentio do Ouro', label: 'Gentio do Ouro' },
        { value: 'Glória', label: 'Glória' },
        { value: 'Gongogi', label: 'Gongogi' },
        { value: 'Governador Mangabeira', label: 'Governador Mangabeira' },
        { value: 'Guajeru', label: 'Guajeru' },
        { value: 'Guanambi', label: 'Guanambi' },
        { value: 'Guaratinga', label: 'Guaratinga' },
        { value: 'Heliópolis', label: 'Heliópolis' },
        { value: 'Iaçu', label: 'Iaçu' },
        { value: 'Ibiassucê', label: 'Ibiassucê' },
        { value: 'Ibicaraí', label: 'Ibicaraí' },
        { value: 'Ibicoara', label: 'Ibicoara' },
        { value: 'Ibicuí', label: 'Ibicuí' },
        { value: 'Ibipeba', label: 'Ibipeba' },
        { value: 'Ibipitanga', label: 'Ibipitanga' },
        { value: 'Ibiquera', label: 'Ibiquera' },
        { value: 'Ibirapitanga', label: 'Ibirapitanga' },
        { value: 'Ibirapoã', label: 'Ibirapoã' },
        { value: 'Ibirataia', label: 'Ibirataia' },
        { value: 'Ibitiara', label: 'Ibitiara' },
        { value: 'Ibititá', label: 'Ibititá' },
        { value: 'Ibotirama', label: 'Ibotirama' },
        { value: 'Ichu', label: 'Ichu' },
        { value: 'Igaporã', label: 'Igaporã' },
        { value: 'Igrapiúna', label: 'Igrapiúna' },
        { value: 'Iguaí', label: 'Iguaí' },
        { value: 'Ilhéus', label: 'Ilhéus' },
        { value: 'Inhambupe', label: 'Inhambupe' },
        { value: 'Ipecaetá', label: 'Ipecaetá' },
        { value: 'Ipiaú', label: 'Ipiaú' },
        { value: 'Ipira', label: 'Ipira' },
        { value: 'Ipira > NTE-15 Ipirá', label: 'Ipira > NTE-15 Ipirá' },
        { value: 'Ipupiara', label: 'Ipupiara' },
        { value: 'Irajuba', label: 'Irajuba' },
        { value: 'Iramaia', label: 'Iramaia' },
        { value: 'Iraquara', label: 'Iraquara' },
        { value: 'Irará', label: 'Irará' },
        { value: 'Irece', label: 'Irece' },
        { value: 'Irece > NTE-01 Irece', label: 'Irece > NTE-01 Irece' },
        { value: 'Itabela', label: 'Itabela' },
        { value: 'Itaberaba', label: 'Itaberaba' },
        { value: 'Itaberaba > NTE-14 Itaberaba', label: 'Itaberaba > NTE-14 Itaberaba' },
        { value: 'Itabuna', label: 'Itabuna' },
        { value: 'Itabuna > NTE-05 Itabuna', label: 'Itabuna > NTE-05 Itabuna' },
        { value: 'Itacaré', label: 'Itacaré' },
        { value: 'Itaeté', label: 'Itaeté' },
        { value: 'Itagi', label: 'Itagi' },
        { value: 'Itagibá', label: 'Itagibá' },
        { value: 'Itagimirim', label: 'Itagimirim' },
        { value: 'Itaguaçu da Bahia', label: 'Itaguaçu da Bahia' },
        { value: 'Itaju do Colônia', label: 'Itaju do Colônia' },
        { value: 'Itajuípe', label: 'Itajuípe' },
        { value: 'Itamaraju', label: 'Itamaraju' },
        { value: 'Itamari', label: 'Itamari' },
        { value: 'Itambé', label: 'Itambé' },
        { value: 'Itanagra', label: 'Itanagra' },
        { value: 'Itanhém', label: 'Itanhém' },
        { value: 'Itaparica', label: 'Itaparica' },
        { value: 'Itapé', label: 'Itapé' },
        { value: 'Itapebi', label: 'Itapebi' },
        { value: 'Itapetinga', label: 'Itapetinga' },
        { value: 'Itapetinga > NTE-08 Itapetinga', label: 'Itapetinga > NTE-08 Itapetinga' },
        { value: 'Itapicuru', label: 'Itapicuru' },
        { value: 'Itapitanga', label: 'Itapitanga' },
        { value: 'Itaquara', label: 'Itaquara' },
        { value: 'Itarantim', label: 'Itarantim' },
        { value: 'Itatim', label: 'Itatim' },
        { value: 'Itiruçu', label: 'Itiruçu' },
        { value: 'Itiúba', label: 'Itiúba' },
        { value: 'Itororó', label: 'Itororó' },
        { value: 'Ituaçu', label: 'Ituaçu' },
        { value: 'Ituberá', label: 'Ituberá' },
        { value: 'Iuiu', label: 'Iuiu' },
        { value: 'Jaborandi', label: 'Jaborandi' },
        { value: 'Jaborandi', label: 'Jaborandi' },
        { value: 'Jacobina', label: 'Jacobina' },
        { value: 'Jacobina > NTE-16 Jacobina', label: 'Jacobina > NTE-16 Jacobina' },
        { value: 'Jaguaquara', label: 'Jaguaquara' },
        { value: 'Jaguarari', label: 'Jaguarari' },
        { value: 'Jaguaripe', label: 'Jaguaripe' },
        { value: 'Jequie', label: 'Jequie' },
        { value: 'Jequie > NTE-22 Jequie', label: 'Jequie > NTE-22 Jequie' },
        { value: 'Jeremoabo', label: 'Jeremoabo' },
        { value: 'Jiquiriçá', label: 'Jiquiriçá' },
        { value: 'Jitaúna', label: 'Jitaúna' },
        { value: 'João Dourado', label: 'João Dourado' },
        { value: 'Juazeiro', label: 'Juazeiro' },
        { value: 'Juazeiro > NTE-10 Juazeiro', label: 'Juazeiro > NTE-10 Juazeiro' },
        { value: 'Jucuruçu', label: 'Jucuruçu' },
        { value: 'Jussara', label: 'Jussara' },
        { value: 'Jussari', label: 'Jussari' },
        { value: 'Jussiape', label: 'Jussiape' },
        { value: 'Lafayette Coutinho', label: 'Lafayette Coutinho' },
        { value: 'Lagedo do Tabocal', label: 'Lagedo do Tabocal' },
        { value: 'Lagoa Real', label: 'Lagoa Real' },
        { value: 'Laje', label: 'Laje' },
        { value: 'Lajedão', label: 'Lajedão' },
        { value: 'Lajedinho', label: 'Lajedinho' },
        { value: 'Lamarão', label: 'Lamarão' },
        { value: 'Lapão', label: 'Lapão' },
        { value: 'Lauro de Freitas', label: 'Lauro de Freitas' },
        { value: 'Lençóis', label: 'Lençóis' },
        { value: 'Licínio de Almeida', label: 'Licínio de Almeida' },
        { value: 'Livramento de Nossa Senhora', label: 'Livramento de Nossa Senhora' },
        { value: 'Luís Eduardo Magalhães', label: 'Luís Eduardo Magalhães' },
        { value: 'Macajuba', label: 'Macajuba' },
        { value: 'Macarani', label: 'Macarani' },
        { value: 'Macaúbas', label: 'Macaúbas' },
        { value: 'Macaúbas > NTE-12 Macaúbas', label: 'Macaúbas > NTE-12 Macaúbas' },
        { value: 'Macururé', label: 'Macururé' },
        { value: 'Madre de Deus', label: 'Madre de Deus' },
        { value: 'Maetinga', label: 'Maetinga' },
        { value: 'Maiquinique', label: 'Maiquinique' },
        { value: 'Mairi', label: 'Mairi' },
        { value: 'Malhada', label: 'Malhada' },
        { value: 'Malhada de Pedras', label: 'Malhada de Pedras' },
        { value: 'Manoel Vitorino', label: 'Manoel Vitorino' },
        { value: 'Mansidão', label: 'Mansidão' },
        { value: 'Maracás', label: 'Maracás' },
        { value: 'Maragogipe', label: 'Maragogipe' },
        { value: 'Maraú', label: 'Maraú' },
        { value: 'Marcionílio Souza', label: 'Marcionílio Souza' },
        { value: 'Mascote', label: 'Mascote' },
        { value: 'Mata de São João', label: 'Mata de São João' },
        { value: 'Matina', label: 'Matina' },
        { value: 'Medeiros Neto', label: 'Medeiros Neto' },
        { value: 'Miguel Calmon', label: 'Miguel Calmon' },
        { value: 'Milagres', label: 'Milagres' },
        { value: 'Mirangaba', label: 'Mirangaba' },
        { value: 'Mirante', label: 'Mirante' },
        { value: 'Monte Santo', label: 'Monte Santo' },
        { value: 'Morpará', label: 'Morpará' },
        { value: 'Morro do Chapéu', label: 'Morro do Chapéu' },
        { value: 'Mortugaba', label: 'Mortugaba' },
        { value: 'Mucugê', label: 'Mucugê' },
        { value: 'Mucuri', label: 'Mucuri' },
        { value: 'Mulungu do Morro', label: 'Mulungu do Morro' },
        { value: 'Mundo Novo', label: 'Mundo Novo' },
        { value: 'Muniz Ferreira', label: 'Muniz Ferreira' },
        { value: 'Muquém do São Francisco', label: 'Muquém do São Francisco' },
        { value: 'Muritiba', label: 'Muritiba' },
        { value: 'Mutuípe', label: 'Mutuípe' },
        { value: 'Não Informado', label: 'Não Informado' },
        { value: 'Nazaré', label: 'Nazaré' },
        { value: 'Nilo Peçanha', label: 'Nilo Peçanha' },
        { value: 'Nordestina', label: 'Nordestina' },
        { value: 'Nova Canaã', label: 'Nova Canaã' },
        { value: 'Nova Fátima', label: 'Nova Fátima' },
        { value: 'Nova Ibiá', label: 'Nova Ibiá' },
        { value: 'Nova Itarana', label: 'Nova Itarana' },
        { value: 'Nova Redenção', label: 'Nova Redenção' },
        { value: 'Nova Soure', label: 'Nova Soure' },
        { value: 'Nova Viçosa', label: 'Nova Viçosa' },
        { value: 'Novo Horizonte', label: 'Novo Horizonte' },
        { value: 'Novo Triunfo', label: 'Novo Triunfo' },
        { value: 'Olindina', label: 'Olindina' },
        { value: 'Oliveira dos Brejinhos', label: 'Oliveira dos Brejinhos' },
        { value: 'Ouriçangas', label: 'Ouriçangas' },
        { value: 'Ourolândia', label: 'Ourolândia' },
        { value: 'Palmas de Monte Alto', label: 'Palmas de Monte Alto' },
        { value: 'Palmeiras', label: 'Palmeiras' },
        { value: 'Paramirim', label: 'Paramirim' },
        { value: 'Paratinga', label: 'Paratinga' },
        { value: 'Paripiranga', label: 'Paripiranga' },
        { value: 'Pau Brasil', label: 'Pau Brasil' },
        { value: 'Paulo Afonso', label: 'Paulo Afonso' },
        { value: 'Paulo Afonso > NTE-24 Paulo Afonso', label: 'Paulo Afonso > NTE-24 Paulo Afonso' },
        { value: 'Pé de Serra', label: 'Pé de Serra' },
        { value: 'Pedrão', label: 'Pedrão' },
        { value: 'Pedro Alexandre', label: 'Pedro Alexandre' },
        { value: 'Piatã', label: 'Piatã' },
        { value: 'Pilão Arcado', label: 'Pilão Arcado' },
        { value: 'Pindaí', label: 'Pindaí' },
        { value: 'Pindobaçu', label: 'Pindobaçu' },
        { value: 'Pintadas', label: 'Pintadas' },
        { value: 'Piraí do Norte', label: 'Piraí do Norte' },
        { value: 'Piripá', label: 'Piripá' },
        { value: 'Piritiba', label: 'Piritiba' },
        { value: 'Planaltino', label: 'Planaltino' },
        { value: 'Planalto', label: 'Planalto' },
        { value: 'Poções', label: 'Poções' },
        { value: 'Pojuca', label: 'Pojuca' },
        { value: 'Ponto Novo', label: 'Ponto Novo' },
        { value: 'Porto Seguro', label: 'Porto Seguro' },
        { value: 'Potiraguá', label: 'Potiraguá' },
        { value: 'Prado', label: 'Prado' },
        { value: 'Presidente Dutra', label: 'Presidente Dutra' },
        { value: 'Presidente Jânio Quadros', label: 'Presidente Jânio Quadros' },
        { value: 'Presidente Tancredo Neves', label: 'Presidente Tancredo Neves' },
        { value: 'Queimadas', label: 'Queimadas' },
        { value: 'Quijingue', label: 'Quijingue' },
        { value: 'Quixabeira', label: 'Quixabeira' },
        { value: 'Rafael Jambeiro', label: 'Rafael Jambeiro' },
        { value: 'Remanso', label: 'Remanso' },
        { value: 'Retirolândia', label: 'Retirolândia' },
        { value: 'Riachão das Neves', label: 'Riachão das Neves' },
        { value: 'Riachão do Jacuípe', label: 'Riachão do Jacuípe' },
        { value: 'Riacho de Santana', label: 'Riacho de Santana' },
        { value: 'Ribeira do Amparo', label: 'Ribeira do Amparo' },
        { value: 'Ribeira do Pombal', label: 'Ribeira do Pombal' },
        { value: 'Ribeira do Pombal > NTE-17 Ribeira do Pombal', label: 'Ribeira do Pombal > NTE-17 Ribeira do Pombal' },
        { value: 'Ribeirão do Largo', label: 'Ribeirão do Largo' },
        { value: 'Rio de Contas', label: 'Rio de Contas' },
        { value: 'Rio do Antônio', label: 'Rio do Antônio' },
        { value: 'Rio do Pires', label: 'Rio do Pires' },
        { value: 'Rio Real', label: 'Rio Real' },
        { value: 'Rodelas', label: 'Rodelas' },
        { value: 'Ruy Barbosa', label: 'Ruy Barbosa' },
        { value: 'Salinas da Margarida', label: 'Salinas da Margarida' },
        { value: 'Salvador', label: 'Salvador' },
        { value: 'Salvador > NTE-26 Salvador', label: 'Salvador > NTE-26 Salvador' },
        { value: 'Salvador > SEC - CAB', label: 'Salvador > SEC - CAB' },
        { value: 'Salvador > SEC - CAB > Sub-Solo', label: 'Salvador > SEC - CAB > Sub-Solo' },
        { value: 'Salvador > SEC - CAB > Térreo', label: 'Salvador > SEC - CAB > Térreo' },
        { value: 'Salvador > SEC - CAB > 1', label: 'Salvador > SEC - CAB > 1' },
        { value: 'Salvador > SEC - CAB > 2', label: 'Salvador > SEC - CAB > 2' },
        { value: 'Salvador > SEC - CAB > 3', label: 'Salvador > SEC - CAB > 3' },
        { value: 'Salvador > SEC - CAB > 4', label: 'Salvador > SEC - CAB > 4' },
        { value: 'Santa Bárbara', label: 'Santa Bárbara' },
        { value: 'Santa Brígida', label: 'Santa Brígida' },
        { value: 'Santa Cruz Cabrália', label: 'Santa Cruz Cabrália' },
        { value: 'Santa Cruz da Vitória', label: 'Santa Cruz da Vitória' },
        { value: 'Santa Inês', label: 'Santa Inês' },
        { value: 'Santa Luzia', label: 'Santa Luzia' },
        { value: 'Santa Maria da Vitoria', label: 'Santa Maria da Vitoria' },
        { value: 'Santa Maria da Vitoria > NTE-23 Santa Maria da Vitória', label: 'Santa Maria da Vitoria > NTE-23 Santa Maria da Vitória' },
        { value: 'Santa Rita de Cássia', label: 'Santa Rita de Cássia' },
        { value: 'Santa Terezinha', label: 'Santa Terezinha' },
        { value: 'Santaluz', label: 'Santaluz' },
        { value: 'Santana', label: 'Santana' },
        { value: 'Santanópolis', label: 'Santanópolis' },
        { value: 'Santo Amaro', label: 'Santo Amaro' },
        { value: 'Santo Antonio de Jesus', label: 'Santo Antonio de Jesus' },
        { value: 'Santo Antonio de Jesus > NTE-21 Santo Antonio de Jesus', label: 'Santo Antonio de Jesus > NTE-21 Santo Antonio de Jesus' },
        { value: 'Santo Estêvão', label: 'Santo Estêvão' },
        { value: 'São Desidério', label: 'São Desidério' },
        { value: 'São Domingos', label: 'São Domingos' },
        { value: 'São Felipe', label: 'São Felipe' },
        { value: 'São Félix', label: 'São Félix' },
        { value: 'São Félix do Coribe', label: 'São Félix do Coribe' },
        { value: 'São Francisco do Conde', label: 'São Francisco do Conde' },
        { value: 'São Gabriel', label: 'São Gabriel' },
        { value: 'São Gonçalo dos Campos', label: 'São Gonçalo dos Campos' },
        { value: 'São José da Vitória', label: 'São José da Vitória' },
        { value: 'São José do Jacuípe', label: 'São José do Jacuípe' },
        { value: 'São Miguel das Matas', label: 'São Miguel das Matas' },
        { value: 'São Sebastião do Passé', label: 'São Sebastião do Passé' },
        { value: 'Sapeaçu', label: 'Sapeaçu' },
        { value: 'Sátiro Dias', label: 'Sátiro Dias' },
        { value: 'Saubara', label: 'Saubara' },
        { value: 'Saúde', label: 'Saúde' },
        { value: 'Seabra', label: 'Seabra' },
        { value: 'Seabra > NTE-03 Seabra', label: 'Seabra > NTE-03 Seabra' },
        { value: 'Sebastião Laranjeiras', label: 'Sebastião Laranjeiras' },
        { value: 'Senhor do Bonfim', label: 'Senhor do Bonfim' },
        { value: 'Senhor do Bonfim > NTE-25 Senhor do Bonfim', label: 'Senhor do Bonfim > NTE-25 Senhor do Bonfim' },
        { value: 'Sento Sé', label: 'Sento Sé' },
        { value: 'Serra do Ramalho', label: 'Serra do Ramalho' },
        { value: 'Serra Dourada', label: 'Serra Dourada' },
        { value: 'Serra Preta', label: 'Serra Preta' },
        { value: 'Serrinha', label: 'Serrinha' },
        { value: 'Serrinha > NTE-04 Serrinha', label: 'Serrinha > NTE-04 Serrinha' },
        { value: 'Serrolândia', label: 'Serrolândia' },
        { value: 'Simões filho', label: 'Simões filho' },
        { value: 'Sítio do Mato', label: 'Sítio do Mato' },
        { value: 'Sítio do Quinto', label: 'Sítio do Quinto' },
        { value: 'Sobradinho', label: 'Sobradinho' },
        { value: 'Souto Soares', label: 'Souto Soares' },
        { value: 'Tabocas do Brejo Velho', label: 'Tabocas do Brejo Velho' },
        { value: 'Tanhaçu', label: 'Tanhaçu' },
        { value: 'Tanque Novo', label: 'Tanque Novo' },
        { value: 'Tanquinho', label: 'Tanquinho' },
        { value: 'Taperoá', label: 'Taperoá' },
        { value: 'Tapiramutá', label: 'Tapiramutá' },
        { value: 'Teixeira de Freitas', label: 'Teixeira de Freitas' },
        { value: 'Teixeira de Freitas > NTE-07 Teixeira de Freitas', label: 'Teixeira de Freitas > NTE-07 Teixeira de Freitas' },
        { value: 'Teodoro Sampaio', label: 'Teodoro Sampaio' },
        { value: 'Teofilândia', label: 'Teofilândia' },
        { value: 'Teolândia', label: 'Teolândia' },
        { value: 'Terra nova', label: 'Terra nova' },
        { value: 'Tremedal', label: 'Tremedal' },
        { value: 'Tucano', label: 'Tucano' },
        { value: 'Uauá', label: 'Uauá' },
        { value: 'Ubaíra', label: 'Ubaíra' },
        { value: 'Ubaitaba', label: 'Ubaitaba' },
        { value: 'Ubatã', label: 'Ubatã' },
        { value: 'Uibaí', label: 'Uibaí' },
        { value: 'Umburanas', label: 'Umburanas' },
        { value: 'Una', label: 'Una' },
        { value: 'Urandi', label: 'Urandi' },
        { value: 'Uruçuca', label: 'Uruçuca' },
        { value: 'Utinga', label: 'Utinga' },
        { value: 'Valenca', label: 'Valenca' },
        { value: 'Valenca > NTE-06 Valença', label: 'Valenca > NTE-06 Valença' },
        { value: 'Valente', label: 'Valente' },
        { value: 'Várzea da Roça', label: 'Várzea da Roça' },
        { value: 'Várzea do Poço', label: 'Várzea do Poço' },
        { value: 'Várzea Nova', label: 'Várzea Nova' },
        { value: 'Varzedo', label: 'Varzedo' },
        { value: 'Vera cruz', label: 'Vera cruz' },
        { value: 'Vereda', label: 'Vereda' },
        { value: 'Vitoria da Conquista', label: 'Vitoria da Conquista' },
        { value: 'Vitoria da Conquista > NTE-20 Vitoria da Conquista', label: 'Vitoria da Conquista > NTE-20 Vitoria da Conquista' },
        { value: 'Wagner', label: 'Wagner' },
        { value: 'Wanderley', label: 'Wanderley' },
        { value: 'Wenceslau Guimarães', label: 'Wenceslau Guimarães' },
        { value: 'Xique-Xique', label: 'Xique-Xique' },
    ];

    return (
        <OptionCityNte>
            <div className="select-city-nte">
                <label className="acting-city-nte">Qual o local/cidade?</label>
                <Select options={options} onSelectChange={onChange} value={value} />
            </div>
        </OptionCityNte>

    );

};

SelectCityNte.prototypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectCityNte;