import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: url,
});

export async function createProfile(payload) {
  try {
    const response = await api.post(`/user`, payload);
    if (response.status === 201) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, message: response.data.error };
    }
  } catch (error) {
    // console.log(error)
    if (error.response.status === 400 && error.response.data.msg === 'User already exists') {
      return { success: false, message: "Usuário já existe." };
    }
    return { success: false, message: "Erro ao realizar a requisição." };
  }
}

// export async function outsourcedProfile(payload) {
//   try {
//     const response = await api.post(`/user`, payload);

//     if (response.status === 201) {
//       return { success: true, message: response.data.message };
//     } else {
//       return { success: false, message: response.data.error };
//     }
//   } catch (error) {

//     return { success: false, message: "Erro ao realizar a requisição." };

//   }
// }

// export async function directorProfile(payload) {
//   try {
//     const response = await api.post(`/user`, payload);

//     if (response.status === 201) {
//       return { success: true, message: response.data.message };
//     } else {
//       return { success: false, message: response.data.error };
//     }
//   } catch (error) {

//     return { success: false, message: "Erro ao realizar a requisição." };

//   }
// }

// export async function teacherProfile(payload) {
//   try {
//     const response = await api.post(`/user`, payload);

//     if (response.status === 201) {
//       return { success: true, message: response.data.message };
//     } else {
//       return { success: false, message: response.data.error };
//     }
//   } catch (error) {

//     return { success: false, message: "Erro ao realizar a requisição." };

//   }
// }

// export async function serverProfile(payload) {
//   try {
//     const response = await api.post(`/user`, payload);

//     if (response.status === 201) {
//       return { success: true, message: response.data.message };
//     } else {
//       return { success: false, message: response.data.error };
//     }
//   } catch (error) {

//     return { success: false, message: "Erro ao realizar a requisição." };

//   }
// }