import React from "react";
import PropTypes from 'prop-types';
import { Login } from './styled';

function InputLogin({ id, name, onChange }) {


    return (
        <Login>
            <div className="input-login">
                <span className="title-login">Qual seu login de rede?</span>
                <label>
                    <input
                        type="text"
                        id={id}
                        name={name}
                        onChange={onChange}
                        placeholder="Digite seu login de rede para acessar"
                        // required
                    />
                </label>
            </div>
        </Login>
    )
};

InputLogin.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default InputLogin;
