import { useState } from "react";
import LogoGov from "../../assets/LogoGovBA.png";
import RadioButton from "../RadioButton/radio";
import InputLogin from "../Input/login";
import InputEmail from "../Input/email";
import SelectNte from "../Dropdown/optionNte";
import { validateEmail, validateLogin } from "../../services/authApi";
import { createProfile } from "../../services/connector";
import { useParams } from "react-router-dom";
import SelectSede from "../Dropdown/optionSede";
import Select from "../Dropdown/Select";
import SelectCityNte from "../Dropdown/optionCityNte";
import { Loader } from "../Loader/loader";
import { isValidEmail } from "../../utils/regex";
import * as S from "./styled"


export function FormAutentication() {

    const [optionProfile, setOptionProfile] = useState("");
    const [user_email, setUserEmail] = useState("");
    const [user_login, setUserLogin] = useState("");
    const [user_localization, setUserLocalization] = useState("");
    // const [user_name, setUserName] = useState("");
    const [user_sector, setUserSector] = useState("");
    const [user_location_city, setUserLocationCity] = useState("");
    const { numTelefone } = useParams()
    const [userError, setUserError] = useState(true);
    const [userSuccess, setUserSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (event) => {
        setOptionProfile(event.target.value);
        setUserSector('');
        setUserEmail('');
        setUserLogin('');
        setUserLocalization('');
        // setUserName('');
        setUserLocationCity('');
        setIsChecked(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const inputValidationResult = verifyInput();
        if (inputValidationResult) {
            setLoading(false);
            return;
        } else {
            if (!isChecked) {
                setUserError("Aceite o termo de consentimento!")
                userErrorTimeout('')
                
            }else{
                setLoading(true);
                try {
                    await verifyExecution();
                } catch (error) {
                    console.error("Erro geral:", error);
                }
            } 
        }
        setLoading(false);
        setIsChecked(false);
    }

    const verifyExecution = async () => {
        try {
            if (optionProfile === 'Professor') {               
                await verifyValidateEmail()
            } else {
                await verifyValidateLogin() 
            }
        } catch (error) {
            console.error("Erro ao verificar perfil:", error);
        }
    };

    const verifyInput = () => {
        switch (optionProfile) {
            case 'Servidor':
            case 'Terceirizado':
                if (!user_sector) {
                    setUserError('Selecione o local de atuação!');
                    userErrorTimeout();
                    return true;
                }
                if (user_sector === 'Sede' && !user_localization) {
                    setUserError('Por favor, selecione o setor de atuação!');
                    userErrorTimeout();
                    return true;
                } else if (user_sector === 'NTE' && (!user_localization || !user_location_city)) {
                    setUserError('Por favor, preenhca todos os campos!');
                    userErrorTimeout();
                    return true;
                }
                break;
            case 'Diretor':
                if (!user_localization || !user_location_city) {
                    setUserError('Por favor, preenhca todos os campos!');
                    userErrorTimeout();
                    return true;
                }
                break;
            default:
                return false;
        }

        return false;
    };

    const verifyValidateEmail = async () => {
        try {
            const validationResult = await validateEmailAuth();
            if (validationResult) {
                // const { nameUserFormat, user_email } = validationResult;
                await createUser(validationResult);
            } else {
                console.log("Erro na validação de e-mail");
            }
        } catch (error) {
            console.error("Erro:", error);
        }
    };

    const verifyValidateLogin = async () => {
        try {
            const validationResult = await validateLoginAuth();
            if (validationResult) {
                // const { nameUserFormat, user_email } = validationResult;
                await createUser(validationResult);

            } else {
                console.log("Erro na validação do login.");
            }
        } catch (error) {
            console.error("Erro:", error);
        }
    };

    const validateEmailAuth = async () => {

        try {
            if (!user_email.trim()) {
                setUserError("Informe o seu E-mail!")
                userErrorTimeout('')
            } else if (!isValidEmail.test(user_email)) {
                console.log(user_email)
                setUserError("Informe o E-mail no formato válido!")
                userErrorTimeout('')

            } else {
                const { data } = await validateEmail(user_email);
                const email = data.user_email
                const login = data.user_name
                return {
                    login,
                    email,
                };

            }
                //setValidateError("Usuário autenticado com sucesso!");
            } catch (error) {

            setUserError("E-mail Inválido!");
            userErrorTimeout('')
            console.log("Erro ao validar o email:", error);
            throw error;
        }
    }

    const validateLoginAuth = async () => {
        try {
            if (!user_login.trim()) {
                setUserError("Informe o seu login!")
                userErrorTimeout('')
            } else {
                const { data } = await validateLogin(user_login);
                const email = data.user_email
                const login = data.user_name
                return {
                    login,
                    email
                };
            }
        } catch (error) {
            setUserError("Login Inválido!");
            userErrorTimeout('')
            console.log("Erro ao validar o login:", error);
            throw error;
        }
    };

    const createUser = async ({ login, email}) => {
        const user = {
            user_email: email,
            user_name: login,
            user_sector: user_sector,
            user_localization: user_localization,
            user_location_city: user_location_city,
            user_phone: numTelefone,
        };
        
        console.log("user", user);
        
        if (user_sector === "Sede") {
            user.user_location_city = "Salvador"
        } 
        if (optionProfile === "Professor") {
            user.user_location_city = "Salvador"
            user.user_sector = "Sede"
            user.user_localization = "SAC EDUCAÇÃO"
        } else if (optionProfile === "Diretor") {
            user.user_sector = "NTE"
        }
        
        try {
            const response = await createProfile(user);
            if (response.success) {
                setUserSuccess("Usuário autenticado com sucesso!");
                userSuccessTimeout()
            }
            //  else if (response.message === "Usuário já cadastrado!") {
            else {
                setUserError(response.message);
                userErrorTimeout()
            }
        } catch (error) {
            console.error("Erro ao criar o usuário:", error);
        }
    };

    const userSuccessTimeout = () => {
        setTimeout(() => {
            setUserSuccess(false);
            setUserEmail('');
            setUserLogin('');
            setUserSector('');
            setUserLocalization('');
            // setUserName('');
            setUserLocationCity('');
            setOptionProfile('');
        }, 5000);
    }

    const userErrorTimeout = () => {
        setTimeout(() => {
            setUserError(false);
        }, 5000);
    }


    //DROPDOWN
    const options = [
        { value: '', label: 'Selecione uma opção' },
        { value: 'Sede', label: 'Sede' },
        { value: 'NTE', label: 'NTE' },
    ];

    const showFields = () => {
        switch (user_sector) {
            case 'Sede':
                return [<SelectSede
                    name="select"
                    id="select"
                    onChange={(e) => { setUserLocalization(e.target.value) }}
                    value={user_localization}
                    required
                />]
            case 'NTE':
                return [<SelectNte
                    type="text"
                    name="select"
                    id="select"
                    onChange={(e) => { setUserLocalization(e.target.value) }}
                    value={user_localization}
                    required
                />,
                <SelectCityNte
                    type="text"
                    name="select"
                    id="select"
                    onChange={(e) => { setUserLocationCity(e.target.value) }}
                    value={user_location_city}
                    required
                />]
            default:
                return null;
        }
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) :
                (null)}
            <S.Container>
                <S.Form>
                    <div className="area-form">
                        <div className="header-form">
                            <span className="logo-gov">
                                <img src={LogoGov} alt="LogoGov" width={"190px"} height={"90px"} />
                            </span>
                            <div className="text-header">
                                <p className="title-header">
                                    FORMULÁRIO DE AUTENTICAÇÃO PARA USO DO CHATBOT DA CENTRAL DE ATENDIMENTO
                                </p>
                            </div>
                        </div>
                        <div className="validate-user">
                            {userError ? (
                                <span className="error">{userError}</span>
                            ) : null}
                            {userSuccess ? (
                            <span className="success">{userSuccess}</span>
                            ):null}
                        </div>
                        <form id="formRadio" className="bodyForm" onSubmit={handleSubmit}>
                            <span className="question-profile">
                                Quem é você na SEC?
                            </span>
                            <div className="area-radio">
                                <div className="radio-button">
                                    <RadioButton
                                        value="Servidor"
                                        label="Servidor"
                                        name="perfil"
                                        checked={optionProfile === 'Servidor'}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="radio-button">
                                    <RadioButton
                                        value="Terceirizado"
                                        label="Terceirizado"
                                        name="perfil"
                                        checked={optionProfile === 'Terceirizado'}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="radio-button">
                                    <RadioButton
                                        value="Professor"
                                        label="Professor"
                                        name="perfil"
                                        checked={optionProfile === 'Professor'}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="radio-button">
                                    <RadioButton
                                        value="Diretor"
                                        label="Diretor"
                                        name="perfil"
                                        checked={optionProfile === 'Diretor'}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="campusProfile">
                                {optionProfile === 'Servidor' && (
                                    <div>
                                        <InputLogin
                                            type="text"
                                            name="login"
                                            id="login"
                                            onChange={(e) => { setUserLogin(e.target.value) }}
                                            value={user_login}
                                            required
                                        />
                                        <S.Dropdown>
                                            <div className="area-dropdown">
                                                <div className="sl-acting">
                                                    <label className="acting">Onde você está atuando?</label>
                                                    <Select
                                                        options={options}
                                                        value={user_sector}
                                                        onSelectChange={(e) => { setUserSector(e.target.value) }}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {showFields()}
                                            </div>
                                        </S.Dropdown>
                                    </div>
                                )}
                                {optionProfile === 'Terceirizado' && (
                                    <div>
                                        <InputLogin
                                            type="text"
                                            name="login"
                                            id="login"
                                            onChange={(e) => { setUserLogin(e.target.value) }}
                                            value={user_login}
                                            required
                                        />
                                        <S.Dropdown>
                                            <div className="area-dropdown">
                                                <div className="sl-acting">
                                                    <label className="acting">Onde você está atuando?</label>

                                                    <Select
                                                        options={options}
                                                        value={user_sector}
                                                        onSelectChange={(e) => { setUserSector(e.target.value) }}
                                                        required />
                                                </div>
                                            </div>
                                            <div>
                                                {showFields()}
                                            </div>
                                        </S.Dropdown>
                                    </div>
                                )}
                                {optionProfile === 'Professor' && (
                                    <div>
                                        <InputEmail
                                            type="text"
                                            name="email"
                                            id="email"
                                            onChange={(e) => { setUserEmail(e.target.value) }}
                                            required
                                        />
                                    </div>
                                )}
                                {optionProfile === 'Diretor' && (
                                    <div>
                                        <InputLogin
                                            type="text"
                                            name="login"
                                            id="login"
                                            required
                                            onChange={(e) => { setUserLogin(e.target.value) }}
                                        />
                                        <SelectNte
                                            type="text"
                                            name="select"
                                            id="select"
                                            onChange={(e) => { setUserLocalization(e.target.value) }}
                                            value={user_localization}
                                            required
                                        />
                                        <SelectCityNte
                                            type="text"
                                            name="select"
                                            id="select"
                                            required
                                            onChange={(e) => { setUserLocationCity(e.target.value) }}
                                            value={user_location_city}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="term-consent">
                                <p className="term-1">
                                    Para fins de segurança, solicitamos que seja aceito o termo de  consentimento de uso. Ao utilizar este canal, declaro estar ciente e de  acordo em disponibilizar meus dados funcionais para abertura e acompanhamento de tickets conduzido pela Central de Serviços.
                                </p>
                                <p className="term-2">
                                    Este consentimento é necessário para atendimento aos requisitos da <br /> Lei Geral de Proteção de Dados (LGPD).
                                </p>
                            </div>
                            <div className="accept-term">
                                <label className="check-term">
                                    <input
                                        type="checkbox"
                                        name="Termo"
                                        id="Termo"
                                        value="Termo "
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    // required
                                    />
                                </label>
                                <span for="checkboxMarked" class="term-4">Termo de consentimento</span>

                            </div>
                            <div className="btn-form">
                                <input
                                    type="submit"
                                    name="Entrar"
                                    value="Entrar"
                                    className="enter-btn-form"
                                // onClick={handleButtonClick}
                                // clicked={buttonClick}
                                />
                            </div>
                        </form>
                    </div>
                </S.Form>
            </S.Container>
        </>
    );

}
