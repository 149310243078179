import styled from "styled-components";


export const Dropdown = styled.div`
display: flex;
justify-content: center;
flex-direction:column;

.area-dropdown {
    display:flex; 
}

.acting {
    font: normal normal bold 12px/14px Montserrat; 
    color: #1d7acc;
    margin-right: 0.7rem;
}

.sl-acting{
    display:flex;
}

.options-sector {
    flex-direction:column;
    margin-top: 10px; 
}


.options-nte {
    margin-top: 10px;
}

`

export const OptionsSede = styled.div`
.acting-sede {
    font: normal normal bold 12px/14px Montserrat; 
    color: #1d7acc;
}

.select-sede{
    display:flex;
    margin-top: 1rem;
}

@media (max-width:890px) {
    .select-sede{
        display: block;
        margin-top: 0.2rem;
    }
   
}
`
export const OptionsNte = styled.div`
.select-nte{
    display: flex;
    margin-top: 1rem;  
}

.acting-nte {
    font: normal normal bold 12px/14px Montserrat; 
    color: #1d7acc;
    margin-right: 4.4rem;
}

@media (max-width:890px) {
    .select-nte{
        display: block;
        margin-top: 0.2rem;
    }
   
}
`
export const OptionCityNte = styled.div`

.select-city-nte{
    display: flex;
    margin-top: 1rem;
}

.acting-city-nte {
    font: normal normal bold 12px/14px Montserrat; 
    color: #1d7acc;
    margin-right: 2.8rem;
}

@media (max-width:890px) {
    .select-city-nte{
        display: block;
        margin-top: 0.2rem;
    }
   
}

`
export const Selected = styled.div`
select {
    width:182px;
    height:20px;
    font: normal normal normal 12px/13px Montserrat;
    color: #707070;  
    margin-left: 2rem;
}


@media (max-width:500px) {
    margin-left: -2rem;
    margin-top: 0.5rem;
}

@media (max-width:890px) {
    margin-left: -2rem;
    margin-top: 0.5rem;

}
`