import { React } from 'react';
import Select from "./Select";
import { OptionsSede } from "./styled";
import PropTypes from 'prop-types';


function SelectSede({ value, onChange }) {

    // const [selecSedetOptions, setSelecSedetOptions] = useState('');

    // const handleSedeOptionSelect = (event) => {
    //     setSelecSedetOptions(event.target.value)
    // }

    const options = [
        { value: '', label: 'Selecione uma opção' },
        { value: 'APG', label: 'APG' },
        { value: 'ASCOM', label: 'ASCOM' },
        { value: 'CEE', label: 'CEE' },
        { value: 'COAPE', label: 'COAPE' },
        { value: 'COINF', label: 'COINF' },
        { value: 'COFE', label: 'COFE' },
        { value: 'CORREGEDORIA', label: 'CORREGEDORIA' },
        { value: 'CS-TIC', label: 'CS-TIC' },
        { value: 'DG', label: 'DG' },
        { value: 'IAT', label: 'IAT' },
        { value: 'GABINETE', label: 'GABINETE' },
        { value: 'NCAA', label: 'NCAA' },
        { value: 'SGINF', label: 'SGINF' },
        { value: 'SUBSECRETARIA', label: 'SUBSECRETARIA' },
        { value: 'SUDEPE', label: 'SUDEPE' },
        { value: 'SUPEC', label: 'SUPEC' },
        { value: 'SUPED', label: 'SUPED' },
        { value: 'SUPROT', label: 'SUPROT' },
        { value: 'OUVIDORIA', label: 'OUVIDORIA' },
        { value: 'POLOS UAB', label: 'POLOS UAB' },
        { value: 'ALMOXARIFADO', label: 'ALMOXARIFADO' },
        { value: 'IRDEB', label: 'IRDEB' },
        { value: 'SAC EDUCAÇÃO', label: 'SAC EDUCAÇÃO' },
    ];

    return (
        <OptionsSede>
            <div className="select-sede">
                <label className="acting-sede">Qual seu setor de atuação?</label>
                <Select options={options} onSelectChange={onChange} value={value} />
            </div>
        </OptionsSede>

    );

};

SelectSede.prototypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectSede;

