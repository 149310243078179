import React from "react";
import PropTypes from 'prop-types';

function RadioButton({ value, label, checked, onChange, name }) {
    return (
        <div>
            <label>
                <input
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    required
                />
                {label}
            </label>
        </div>

    )
};

RadioButton.propTypes = {
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default RadioButton;